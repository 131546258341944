/*
 * Created by zhangq on 2021/09/03
 * 语言包列表
 */
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import XLSX from 'xlsx'
import style from '../style.module.scss'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { Card, Switch, Modal, Form, Input, FormInstance } from 'antd'
import {
  createCategories,
  deletePackageById,
  getPackagesByGroupId,
  patchPackageByIdWithDisabled,
  editCategories,
} from '../../../api/package'
import { formatDate } from '@/utils/date'
import { uploadOptions } from '@/view/editer/utils'

const options = {
  add: '添加语种',
  edit: '编辑语种',
}

export default ({ groupId }: SetPage.ListProps): ReactElement => {
  /** state */
  const [list, setList] = useState<SetPage.PackageItem[]>([])
  const [uploadFileVisible, setUploadFileVisible] = useState<boolean>(false)
  const form = useRef<FormInstance<any> | null>(null)
  const [title, setTitle] = useState(options.add)
  const [initialValues, setInitialValues] = useState({})
  const [editId, setEditId] = useState('')

  const update = useCallback(() => {
    if (groupId) {
      getPackagesByGroupId(groupId).then((res) => {
        setList(res)
      })
    }
  }, [groupId])

  useEffect(() => {
    update()
  }, [update])

  const filehandle = () => {
    if (form.current) {
      form.current
        .validateFields()
        .then((v) => {
          form.current?.submit()
        })
        .catch((e) => {})
    }
  }

  const changeStatus = useCallback(
    (status: boolean, ele: SetPage.PackageItem) => {
      patchPackageByIdWithDisabled(ele.id, status).then(() => {
        const data = list.map((item) => {
          if (item.key === ele.key) {
            return {
              ...item,
              disabled: status,
            }
          }
          return item
        })
        setList(data)
      })
    },
    [list],
  )

  const _delete = (key: string, name: string, id: string) => {
    const ok = () => {
      deletePackageById(id).then(() => {
        update()
      })
    }
    Modal.confirm({
      okType: 'danger',
      content: <div>确定删除语言包 “{name}” ?</div>,
      onOk: () => {
        ok()
      },
    })
  }

  const handleEditor = useCallback((key: string, name: string, id: string) => {
    setTitle(options.edit)
    setEditId(id)
    if (form.current) {
      form.current.setFieldsValue({
        name: name,
        key: key,
      })
    } else {
      setInitialValues({
        name: name,
        key: key,
      })
    }

    setUploadFileVisible(true)
  }, [])

  const handleSubmit = useCallback(
    (values: any) => {
      if (title === options.add) {
        createCategories({
          name: values.name,
          key: values.key,
          groupId: groupId,
        }).then((res: any) => {
          update()
          setUploadFileVisible(false)
        })
      } else {
        editCategories(editId, {
          name: values.name,
          key: values.key,
          groupId: groupId,
        }).then((res: any) => {
          update()
          setUploadFileVisible(false)
        })
      }
    },
    [groupId, update, title, editId],
  )

  return (
    <div className={style['package-centent']}>
      <Modal
        title={title}
        visible={uploadFileVisible}
        onOk={filehandle}
        onCancel={() => setUploadFileVisible(false)}
      >
        <Form
          name="upload_file"
          initialValues={initialValues}
          ref={form}
          layout={uploadOptions.layout}
          onFinish={handleSubmit}
        >
          <Form.Item
            rules={uploadOptions.name.rules}
            label={uploadOptions.name.label}
            name={uploadOptions.name.name}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={uploadOptions.key.rules}
            label={uploadOptions.key.label}
            name={uploadOptions.key.name}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {list.map((ele) => {
        return (
          <Card
            key={ele.id}
            hoverable={true}
            style={{
              height: 150,
              width: 300,
              marginLeft: 5,
              marginTop: 30,
              marginRight: 25,
            }}
            bodyStyle={{
              height: 95,
            }}
            actions={[
              <Switch
                size="small"
                checked={!ele.disabled}
                onChange={(e) => changeStatus(!e, ele)}
                defaultChecked
                key="status"
              />,
              <EditOutlined key="editor" onClick={() => handleEditor(ele.key, ele.name, ele.id)} />,
              <DeleteOutlined key="delete" onClick={() => _delete(ele.key, ele.name, ele.id)} />,
            ]}
          >
            <div>
              {ele.name}（{ele.key}）
            </div>
            <div>{formatDate(ele.updatedAt)}</div>
          </Card>
        )
      })}
      <div
        className={style['item-add']}
        onClick={() => {
          setTitle(options.add)
          setUploadFileVisible(true)
        }}
      >
        <PlusOutlined style={{ fontSize: 35, color: '#666' }} />
      </div>
    </div>
  )
}
