/*
 * Created by zhangq on 2021/09/02
 * 工具栏
 */
import { ReactElement, useState, useCallback, useRef, useMemo, useEffect } from 'react'
import XLSX from 'xlsx'
import style from '../style.module.scss'
import {
  EditFilled,
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  TranslationOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import {
  Button,
  Upload,
  Input,
  Modal,
  Form,
  message,
  Select,
  FormInstance,
  Radio,
  Popconfirm,
  Switch,
  Spin,
} from 'antd'
import { uploadOptions } from '../utils'
import {
  addLanguageLine,
  languagePublish,
  upload,
  translate,
  languageMultiple,
  editLanguageLine,
  deleteLanguageDataByGroupID,
} from '@/api/package'
import { UploadFile } from 'antd/lib/upload/interface'
import { useSocketIO } from '../hooks/socket'
import { ActionType } from '../interface'
import { useSelector } from 'react-redux'
import { userSelector } from '@/view/login/selectors'
import { Subject, of, defer } from 'rxjs'
import { debounceTime, filter, map, distinctUntilChanged } from 'rxjs/operators'
import { useLocation } from 'react-router'
import { last } from 'lodash'
import JsonEditor from './json'
import './headerTools.scss'

const { Dragger } = Upload
export default ({
  exportJsonFile,
  categories,
  update,
  group,
  socket,
  source,
  onFilter,
  dataSourceFlag,
}: HomePage.HeaderToolsProps & {
  socket: ReturnType<typeof useSocketIO>
  onFilter: (e: boolean) => void
  source: string[][]
  dataSourceFlag: HomePage.DataSource[]
}): ReactElement => {
  /** state */
  const [uploadFileVisible, setUploadFileVisible] = useState<boolean>(false)
  const [file, setFile] = useState<any>(null)
  const [fileType, setFileType] = useState<any>(null)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [addVisible, setAddVisible] = useState<boolean>(false)
  const form = useRef<FormInstance<any> | null>(null)
  const formLine = useRef<FormInstance<any> | null>(null)
  const [language, setLanguage] = useState<Record<string, string>[]>([])
  const [spinning, setSpinning] = useState(false)
  const [jsonVisable, setJsonVisable] = useState(false)
  const [json, setJson] = useState<{
    [key: string]: string
  }>({})
  const [clearValue, setClearValue] = useState(false)
  const [isEdit, setEditStatus] = useState(false)
  const user = useSelector(userSelector)
  const location = useLocation()
  const cols = useMemo(() => {
    const j = language[0]
    const c = []
    for (const key in j) {
      c.push({
        key: key,
        value: key,
      })
    }
    return c
  }, [language])

  const filehandle = () => {
    if (form.current) {
      form.current
        .validateFields()
        .then((v) => {
          form.current?.submit()
        })
        .catch((e) => {})
    }
  }

  const handleAddOk = useCallback(() => {
    if (formLine.current) {
      formLine.current
        .validateFields()
        .then((v) => {
          formLine.current?.submit()
        })
        .catch((e) => {})
    }
  }, [])

  const parseFile = useCallback((file: UploadFile) => {
    return new Promise<Record<string, string>[]>((resolve, reject) => {
      if (file) {
        const reader = new FileReader()
        let value: { [key: string]: string }[] = []
        let key = file.name.split('.')[1].toLocaleLowerCase()
        if (key === 'json') {
          reader.onload = (e: ProgressEvent<FileReader>) => {
            try {
              if (typeof e.target?.result === 'string') {
                value = JSON.parse(e.target?.result)
                resolve(value)
              }
            } catch (e: any) {
              message.error(e)
              reject()
            }
          }
          reader.readAsText(file as unknown as Blob)
        }

        if (key === 'xlsx' || key === 'xls') {
          reader.onload = ({ currentTarget }: { currentTarget: any }) => {
            try {
              const cn = XLSX.read(currentTarget.result, {
                type: 'array',
              })
              const key = Object.keys(cn.Sheets)[0]
              const json = XLSX.utils.sheet_to_json(cn.Sheets[key])
              resolve(json as any)
            } catch (e: any) {
              message.error(e)
              reject()
            }
          }
          reader.readAsArrayBuffer(file as unknown as Blob)
        }
      }
    })
  }, [])

  const uploadFile = useCallback(
    (categoryId: any, col: string) => {
      let data = []
      if (Array.isArray(language)) {
        data = language.map((item) => {
          return {
            key: item[cols[0].key],
            value: item[col],
          }
        })
      } else {
        const languages = language as Record<string, string>
        for (const k in languages) {
          const value = languages[k]
          data.push({
            key: k,
            value: value,
          })
        }
      }
      return upload(data, categoryId)
        .then((res) => {
          setUploadFileVisible(false)
        })
        .catch(() => {
          setConfirmLoading(false)
        })
    },
    [language, cols],
  )

  const handleSubmit = useCallback(
    (values: any) => {
      setConfirmLoading(true)
      uploadFile(values.categoryId, values.language).then(() => {
        update()
        setConfirmLoading(false)
      })
    },
    [update, uploadFile],
  )

  const handleSubmitLine = useCallback(
    (values) => {
      const data: {
          categoryId: string
          value: string
        }[] = [],
        socketData: any[] = []
      for (const key in values) {
        const categorie = categories.find((categorie) => categorie.key === key)
        if (categorie) {
          if (values[key]) {
            data.push({
              categoryId: categorie.id,
              value: values[key],
            })
          }
          socketData.push(values[key])
        }
      }
      setConfirmLoading(true)
      const fn = isEdit ? editLanguageLine : addLanguageLine
      fn({
        key: values.key,
        groupId: group,
        data: data,
      })
        .then(() => {
          socket.emit('broadcast', {
            type: ActionType.ADD_LINE,
            x: 0,
            y: 0,
            key: values.key,
            value: socketData,
            page: 0,
            id: user.id,
          })

          update()
          setAddVisible(false)
          formLine.current?.resetFields()
        })
        .finally(() => {
          setConfirmLoading(false)
        })
    },
    [group, categories, update, socket, user, isEdit],
  )

  const cancelAddLine = useCallback(() => {
    setAddVisible(false)
    formLine.current?.resetFields()
  }, [])

  const handleAddMultiple = useCallback(() => {
    const value = Object.keys(json).map((x) => {
      return {
        key: x,
        value: json[x],
      }
    })
    setConfirmLoading(true)
    setClearValue(false)
    languageMultiple(group, value)
      .then(() => {
        setJsonVisable(false)
        setClearValue(true)
        update()
      })
      .finally(() => {
        setConfirmLoading(false)
      })
  }, [json, group, update])

  const handleJsonChange = useCallback((v: string) => {
    try {
      const data = JSON.parse(v)
      setJson(data)
    } catch (e) {
      message.error('请输入正确的JSON')
    }
  }, [])

  const handlePublish = useCallback(() => {
    return languagePublish({
      groupId: group,
    })
  }, [group])

  const handleClearLanguage = useCallback(() => {
    deleteLanguageDataByGroupID(group).then(() => {
      update()
    })
  }, [group, update])

  const handleFilter = useCallback(
    (e) => {
      onFilter(e)
    },
    [onFilter],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subject = new Subject<{ 'zh-cn': string }>()

  useEffect(() => {
    subject
      .pipe(
        debounceTime(600),
        filter((x) => Boolean(x['zh-cn'])),
        map((x) => x['zh-cn']),
        distinctUntilChanged(),
      )
      .subscribe({
        next: async (value) => {
          setSpinning(true)
          const groupId = last(location.pathname.split('/')) as string
          const res = await translate(groupId, value)
          setSpinning(false)
          if (formLine.current) {
            formLine.current.setFieldsValue(res)
          }
        },
      })
  }, [location, subject, formLine])

  const clearFormValues = useCallback(() => {
    setUploadFileVisible(false)
    setFile(null)
    form.current?.resetFields()
  }, [form])

  return (
    <div className={style['tool-content']}>
      <Modal
        title="添加语言包"
        visible={uploadFileVisible}
        confirmLoading={confirmLoading}
        onOk={filehandle}
        onCancel={clearFormValues}
      >
        <Form name="upload_file" ref={form} layout={uploadOptions.layout} onFinish={handleSubmit}>
          <Form.Item
            rules={uploadOptions.language.rules}
            label={uploadOptions.language.label}
            name={uploadOptions.language.name}
          >
            <Select>
              {categories.map((categorie) => (
                <Select.Option key={categorie.id} value={categorie.id}>
                  {categorie.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={uploadOptions.file.rules}
            label={uploadOptions.file.label}
            name={uploadOptions.file.name}
            valuePropName="file"
          >
            <Dragger
              accept={uploadOptions.file.accept}
              beforeUpload={() => false}
              name={'file'}
              maxCount={1}
              onRemove={() => setFile(null)}
              fileList={file ? [file] : []}
              onChange={({ file }) => {
                setFileType(file.type === 'application/json' ? 'json' : 'excel')
                setFile(file)
                parseFile(file).then((language) => {
                  setLanguage(language)
                })
              }}
            >
              <div>
                <UploadOutlined />
              </div>
              <div>{uploadOptions.file.tip}</div>
            </Dragger>
          </Form.Item>
          {file && fileType === 'excel' && (
            <Form.Item
              label="请选择要上传的列"
              rules={[{ required: true, message: '请选择要上传的列' }]}
              name="language"
            >
              <Radio.Group>
                {cols.map((item, index) => (
                  <Radio key={item.value} value={item.value} disabled={index === 0}>
                    {item.key}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        title={isEdit ? '修改一行数据' : '添加一行数据'}
        visible={addVisible}
        confirmLoading={confirmLoading}
        onOk={handleAddOk}
        onCancel={cancelAddLine}
      >
        <Form
          name="add_options"
          ref={formLine}
          layout={uploadOptions.layout}
          onFinish={handleSubmitLine}
          onValuesChange={(v) => subject.next(v)}
        >
          <Form.Item
            label="key"
            name="key"
            rules={[{ type: 'string', required: true, message: '请输入key值' }]}
          >
            {isEdit ? (
              <Select
                showSearch
                onChange={(flag) => {
                  if (formLine.current) {
                    const s = dataSourceFlag.find((x) => x.value[0] === flag)
                    if (s) {
                      const store = categories.reduce((prev: any, current, index) => {
                        prev[current.key] = s.value[index + 1]
                        return prev
                      }, {})
                      formLine.current.setFieldsValue(store)
                    }
                  }
                }}
              >
                {dataSourceFlag.map((x) => (
                  <Select.Option key={x.flag} value={x.value[0]}>
                    {x.value[0]}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input />
            )}
          </Form.Item>
          {categories.map((ele) => {
            return (
              <Form.Item
                label={ele.name}
                name={ele.key}
                key={ele.key}
                rules={
                  ele.key === 'zh-cn' && !isEdit
                    ? [{ type: 'string', required: true, message: '请输入中文' }]
                    : undefined
                }
              >
                <Input
                  suffix={
                    ele.key !== 'zh-cn' && (
                      <Spin spinning={spinning}>
                        <TranslationOutlined />
                      </Spin>
                    )
                  }
                />
              </Form.Item>
            )
          })}
        </Form>
      </Modal>
      <Modal
        title="批量添加中文"
        visible={jsonVisable}
        confirmLoading={confirmLoading}
        onOk={handleAddMultiple}
        onCancel={() => setJsonVisable(false)}
        className="drak-modal"
        width={800}
        maskClosable={false}
      >
        <JsonEditor clearValue={clearValue} onChange={handleJsonChange} />
      </Modal>

      <div className={style['alcenter']}>
        <Button
          type={'link'}
          icon={<PlusOutlined />}
          size="small"
          style={{ fontSize: '12px' }}
          onClick={() => {
            setAddVisible(true)
            setEditStatus(false)
          }}
        >
          添加一行
        </Button>
        <Button
          type={'link'}
          icon={<EditFilled />}
          size="small"
          style={{ fontSize: '12px' }}
          onClick={() => {
            setAddVisible(true)
            setEditStatus(true)
          }}
        >
          修改一行
        </Button>
        <Button
          size="small"
          type={'link'}
          style={{ fontSize: '12px', marginLeft: 20 }}
          onClick={() => setUploadFileVisible(true)}
          icon={<UploadOutlined />}
        >
          添加语言包（初始化）
        </Button>
        <Button
          size="small"
          type={'link'}
          style={{ fontSize: '12px', marginLeft: 20 }}
          onClick={() => setJsonVisable(true)}
          icon={<UploadOutlined />}
        >
          批量添加中文（新增）
        </Button>
        <Button
          size="small"
          type={'link'}
          style={{ fontSize: '12px', marginLeft: 20 }}
          onClick={exportJsonFile}
          icon={<DownloadOutlined />}
        >
          导出json文件
        </Button>
        <Popconfirm title="确定发布新版本？" onConfirm={handlePublish}>
          <Button
            type="link"
            size="small"
            icon={<CloudUploadOutlined />}
            style={{ fontSize: '12px', marginLeft: 20 }}
          >
            发布版本
          </Button>
        </Popconfirm>
        <Popconfirm title="确定删除语言包？" okType="danger" onConfirm={handleClearLanguage}>
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            style={{ fontSize: '12px', marginLeft: 20 }}
          >
            删除
          </Button>
        </Popconfirm>
        <Switch
          style={{ width: 80, marginLeft: 20 }}
          checkedChildren="已过滤"
          unCheckedChildren="未过滤"
          onChange={handleFilter}
        />
      </div>
    </div>
  )
}
