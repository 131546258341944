import { Spin } from 'antd'

export default () => {
  return (
    <div
      style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Spin />
    </div>
  )
}
