import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { dataInterceptor } from './interceptors/data'

const defaultConfig: AxiosRequestConfig = {
  timeout: 0,
  baseURL: process.env.NODE_ENV === 'production' ? '/api/i18n' : '/api/i18n',
}

export type RequestConfig = AxiosRequestConfig & {
  use?: boolean
  message: string
}

export type PartialRequestConfig = Partial<RequestConfig>

class Service {
  private axiosInstance: AxiosInstance

  constructor() {
    const instance = (this.axiosInstance = axios.create(defaultConfig))
    dataInterceptor(instance)
  }

  get<T>(url: any, data?: any, config: any = {}): Promise<T> {
    return this.axiosInstance.request({ url, method: 'get', params: data, ...config })
  }

  post<T>(url: string, data?: any, config: any = {}): Promise<T> {
    return this.axiosInstance.request({ url, method: 'post', data, ...config })
  }

  put<T>(url: string, data?: any, config: PartialRequestConfig = {}): Promise<T> {
    return this.axiosInstance.request({ url, method: 'put', data, ...config })
  }

  delete<T>(url: string, data?: any, config: PartialRequestConfig = {}): Promise<T> {
    return this.axiosInstance.request({ url, method: 'delete', params: data, ...config })
  }

  patch<T>(url: string, data?: any, config: PartialRequestConfig = {}): Promise<T> {
    return this.axiosInstance.request({ url, method: 'patch', data, ...config })
  }
}

export default new Service()
