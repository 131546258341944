/*
 * Created by zhangq on 2021/09/03
 * 右侧导航栏
 */
import { ReactElement, useEffect, useMemo, useState, memo } from 'react'
import style from '../style.module.scss'
import { Menu } from 'antd'
import { getRoutesConfig } from '../../../router'
import { RouterEnum } from '../../../router/const'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import { routeSelector } from '@/view/login/selectors'
const { SubMenu } = Menu

let subId: string[] = []

export default memo(({ collapsed }: { collapsed: boolean }): ReactElement => {
  const history = useHistory()
  const router = useSelector(routeSelector)
  const [routes, setRoutes] = useState<BasicLayoutType.RoutesItem[]>([])

  const routerMemo = useMemo(() => {
    return router
  }, [router])

  useEffect(() => {
    setRoutes(getRoutesConfig(RouterEnum.MAIN, routerMemo))
  }, [routerMemo])

  const change = ({ key }: any) => {
    history.push(key)
  }

  const renderSub = (subs: BasicLayoutType.RoutesItem[], title: string, icon?: any) => {
    const id = uuid()
    subId.push(id)
    return (
      <SubMenu key={id} icon={icon} title={title}>
        {subs.map((ele) => {
          if (ele.children) {
            return renderSub(ele.children, ele.title, ele.icon)
          } else {
            return (
              <Menu.Item key={ele.path} icon={ele.icon}>
                {ele.title}
              </Menu.Item>
            )
          }
        })}
      </SubMenu>
    )
  }

  return (
    <div className={style['nav-content']} style={{ width: collapsed ? 80 : 220 }}>
      <Menu
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        style={{ height: '100%' }}
        onClick={change}
        selectedKeys={[history.location.pathname]}
        defaultOpenKeys={subId}
      >
        {routes.map((ele) => {
          if (ele.children) {
            return renderSub(ele.children, ele.title, ele.icon)
          } else {
            return (
              <Menu.Item key={ele.path} icon={ele.icon}>
                {ele.title}
              </Menu.Item>
            )
          }
        })}
      </Menu>
    </div>
  )
})
