/*
 * Created by zhangq on 2021/09/01
 * homepage
 */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Sheet, HeaderTools } from './widgets'
import style from './style.module.scss'
import {
  tarnformDataSourceWithJson,
  tarnformColumnsWithJson,
  getAddOptions,
  exportJsonFile,
} from './utils'

import { getPackagesByGroupId, languages } from '@/api/package'
import { useRouteMatch } from 'react-router-dom'
import { useSocketIO } from './hooks/socket'
import { Spin } from 'antd'

export default (): ReactElement => {
  const [loading, updateLoading] = useState(false)
  const [dataSource, setDataSource] = useState<ReturnType<typeof tarnformDataSourceWithJson>>([])
  const [columns, setColumns] = useState<HomePage.ColumnsType>([])
  const [categories, setCategories] = useState<SetPage.PackageItem[]>([])
  const [dataSourceCache, setDataSourceCache] = useState<HomePage.DataSource[]>([])
  const source = useMemo(() => {
    return dataSource.map((data) => data.value)
  }, [dataSource])
  const match = useRouteMatch()
  const group = useMemo(() => {
    const paths = match.path.split('/')
    return paths[paths.length - 1]
  }, [match.path])

  const update = useCallback(() => {
    updateLoading(true)
    getPackagesByGroupId(group).then((categories) => {
      const data = categories.filter((categorie) => !categorie.disabled)
      setCategories(data)
      languages(data.map((categorie) => categorie.id)).then((languages) => {
        const dataSource = tarnformDataSourceWithJson(languages, data)
        setDataSourceCache(dataSource)
        setDataSource(dataSource)
        setColumns(tarnformColumnsWithJson(data))
        updateLoading(false)
      })
    })
  }, [group])

  const onFilter = useCallback(
    (e) => {
      if (e) {
        const data = dataSource.filter((item) => {
          const have = columns.every((o, index) => item.value[index] !== '')
          return !have
        })
        setDataSource(data)
      } else {
        setDataSource(dataSourceCache)
      }
    },
    [dataSource, columns, dataSourceCache],
  )

  const socket = useSocketIO()

  useEffect(() => {
    update()
  }, [update])

  return (
    <div className={style.page}>
      <HeaderTools
        categories={categories}
        exportJsonFile={() => exportJsonFile(source, columns)}
        onFilter={onFilter}
        update={update}
        group={group}
        socket={socket}
        columns={columns}
        source={source}
        dataSourceFlag={dataSource}
      />
      <Spin spinning={loading}>
        <Sheet
          columns={columns}
          source={source}
          dataSourceFlag={dataSource}
          group={group}
          socket={socket}
        />
      </Spin>
    </div>
  )
}
