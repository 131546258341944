/*
 * Created by zhangq on 2021/09/03
 *
 */
import { ReactElement, useEffect, useState } from 'react'
import style from './style.module.scss'
import { Tabs } from 'antd'
import { PackageList } from './widgets'
import { getGroups } from '../../api/package'
import { useHistory } from 'react-router-dom'

const { TabPane } = Tabs
export default (): ReactElement => {
  // const params = useRouteMatch()
  const history = useHistory()
  /** state */
  const [packages, setPackages] = useState<SetPage.GroupType[]>([])
  /** LifeCycle */
  useEffect(() => {
    // init
    getGroups().then((res) => {
      setPackages(res)
      history.replace(`/main/language?id=${res[0]?.id}`)
    })
  }, [history])

  /**
   * @method
   */
  const changeTabs = (id: any) => {
    history.replace(`/main/language?id=${id}`)
  }
  /** render */
  return (
    <div className={style['page-content']}>
      <Tabs onChange={changeTabs}>
        {/* 语言包 */}
        {packages.map((ele) => {
          return (
            <TabPane tab={ele.name} key={ele.id}>
              <PackageList groupId={ele.id} />
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}
