/*
 * Created by zhangq on 2021/09/02
 * 表格
 */
import { ReactElement, useCallback, useRef } from 'react'
import { useSheet } from '../hooks/sheet'
import { userSelector } from '@/view/login/selectors'
import { useSelector } from 'react-redux'
import { useSocketIO } from '../hooks/socket'
import { deleteRow } from '@/api/package'
import { ActionType } from '../interface'
import { JSpreadsheetElement } from 'jspreadsheet-ce'
import style from '../style.module.scss'
import './sheet.scss'

export default ({
  socket,
  ...options
}: HomePage.SheetProps & { socket: ReturnType<typeof useSocketIO> }): ReactElement => {
  const sheetRef: any = useRef(null)
  const user = useSelector(userSelector)
  let flagId: string

  const ondeleterow = function (this: JSpreadsheetElement, ...args: any) {
    const [el, y, x, tr] = args
    tr.forEach((_: any, index: any) => {
      const flag = options.dataSourceFlag[y + index].flag
      if (flag !== flagId) {
        deleteRow(flag).then(() => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          flagId = flag
          socket.emit('broadcast', {
            type: ActionType.DEL_LINE,
            x,
            y: y + index,
            page: this.pageNumber,
            id: user.id,
          })
        })
      }
    })
  }

  // cancel the insert event
  const ondeletecolumn = () => false
  const onbeforeinsertcolumn = () => false
  const onbeforeinsertrow = () => false

  const sheet = useSheet(socket, {
    ...options,
    sheetRef,
    user,
    ondeleterow,
    ondeletecolumn,
    onbeforeinsertcolumn,
    onbeforeinsertrow,
  })

  /** render */
  return (
    <div className={style['sheet-content']}>
      <div ref={sheetRef} />
    </div>
  )
}
