/*
 * Created by zhangq on 2021/09/03
 * home logo
 */
import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, message } from 'antd'

export default (): ReactElement => {
  const history = useHistory()
  const navToHome = () => {
    window.localStorage.clear()
    message.success('操作成功')
    history.push('/login')
  }

  return (
    <Button type="link" style={{ marginLeft: 'auto' }} onClick={navToHome}>
      退出登陆
    </Button>
  )
}
