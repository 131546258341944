/*
 * Created by zhangq on 2021/09/03
 * BasicLayout
 */
import { Button } from 'antd'
import { ReactElement, memo, useState, useMemo } from 'react'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import style from './style.module.scss'
import Header from './widgets/header'
import Menu from './widgets/LeftMenu'

export default memo(({ children }: BasicLayoutType.PageProps): ReactElement => {
  const [collapsed, updateCollapsed] = useState(false)

  const toggleCollapsed = () => {
    updateCollapsed(!collapsed)
  }

  return (
    <div className={style['page-layout']}>
      <div className={style['page-content']}>
        <Menu collapsed={collapsed} />
        <div className={style['page-main']}>
          <div className={style['page-header']}>
            <div className={style['page-header-collapse']} onClick={toggleCollapsed}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <Header />
          </div>
          <div className={style['page-card']}>{children}</div>
        </div>
      </div>
    </div>
  )
})
