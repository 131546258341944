import JsZip from 'jszip'
import { languages as languagesApi, getPackagesByGroupId } from '@/api/package'
import { isNumber, merge, reverse } from 'lodash'

export const uploadOptions: HomePage.UploadFormOptions = {
  layout: 'vertical',
  name: {
    label: '名称',
    rules: [{ type: 'string', required: true, message: '请输入语言包名称' }],
    name: 'name',
  },
  key: {
    label: 'key',
    rules: [{ type: 'string', required: true, message: '请输入 key' }],
    name: 'key',
  },
  language: {
    label: '请选择语种',
    rules: [{ type: 'string', required: true, message: '请选择语种' }],
    name: 'categoryId',
  },
  file: {
    label: '上传文件',
    rules: [{ required: true, message: '请上传文件' }],
    tip: '点击/拖拽文件 , 支持excel 和 json',
    accept: '.xls,.xlsx,.json',
    name: 'file',
  },
}

export const tarnformDataSourceWithJson = (
  languages: ThenArg<ReturnType<typeof languagesApi>>,
  categories: ThenArg<ReturnType<typeof getPackagesByGroupId>>,
) => {
  const source = Array.from({ length: languages.length }) as HomePage.DataSource[]

  languages.forEach((language, row) => {
    source[row] = {
      flag: language.flag.id,
      value: Array.from({ length: categories.length }),
    }
    categories.forEach((categorie, col) => {
      source[row].value[0] = language.flag.key
      if (language.categoryId === categorie.id) {
        source[row].value[col + 1] = language.value
      }
    })
  })

  const hash: Record<string, number> = {}
  const languagesNormalized = source
    .map((language, index) => {
      const key = language.value[0]
      if (isNumber(hash[key])) {
        merge(source[hash[key]], language)
        return {
          flag: '',
          value: [],
        }
      }
      hash[key] = index
      return language
    })
    .filter((language) => !!language.flag)

  return languagesNormalized
}

export const tarnformColumnsWithJson = (
  categories: ThenArg<ReturnType<typeof getPackagesByGroupId>>,
): Array<{ [key: string]: any; title: string }> => {
  const columns: HomePage.ColumnsType = [{ title: 'key', width: 180, key: 'key', readOnly: true }]

  return columns.concat(
    categories.map((categorie) => {
      return {
        ...categorie,
        title: `${categorie.name}（${categorie.key}）`,
        key: categorie.key,
      }
    }),
  )
}

export const getAddOptions = (json: HomePage.JsonDataType): HomePage.AddOptions => {
  const options: HomePage.AddOptions = [{ key: 'key', title: 'key', value: '' }]
  for (let key in json) {
    options.push({
      key,
      title: json[key].title,
      value: '',
    })
  }
  return options
}

// 导出json文件
export const exportJsonFile = (source: string[][], columns: HomePage.ColumnsType) => {
  const zipFile = new JsZip()
  const download = (data: any, filename: string) => {
    const a = document.createElement('a')
    a.download = filename
    a.href = window.URL.createObjectURL(data)
    a.click()
  }
  const jsonData: { [key: string]: any } = {}
  columns.forEach((ele) => {
    if (ele.key && ele.key !== 'key') {
      jsonData[ele.key] = {}
    }
  })
  reverse(source).forEach((ele) => {
    ele.forEach((item, i) => {
      if (columns[i] && columns[i].key && i !== 0 && ele[0] && item) {
        jsonData[columns[i].key][ele[0]] = item
      }
    })
  })

  for (let key in jsonData) {
    zipFile.file(`${key}.json`, JSON.stringify(jsonData[key], undefined, 2))
  }

  zipFile
    .generateAsync({
      type: 'blob',
    })
    .then((content) => {
      download(content, 'locale.zip')
    })
}
