import { delay, put, takeLatest } from 'redux-saga/effects'
import { getExample } from './actions'

function* getExampleWorker() {
  try {
    yield delay(300)
    const example = { id: 1, name: 'Samples', count: 0 }
    yield put(getExample.success(example))
  } catch (e) {
    yield put(getExample.failure())
    console.error(e)
  }
}

export default function* saga() {
  yield takeLatest(getExample.request.match, getExampleWorker)
}
