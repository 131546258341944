import { RouterConfig } from '@/router'
import { AsyncGroupCreator, ActionCreator } from 'payload-action'
import { LoginState } from './interfaces'

export const loginAction = AsyncGroupCreator<
  LoginState,
  {
    token: string
    user: Record<string, string>
  },
  void
>()

export const loadingAction = ActionCreator<boolean>()
export const gRouterAction = AsyncGroupCreator<void, void, void>()
export const gRouteAction = ActionCreator<RouterConfig>()
