import { RouterMap } from '@/router/const'
import { message } from 'antd'
import { AxiosInstance, AxiosResponse } from 'axios'

export type Response<T> = {
  data: T
  code: string
  message: string
}

export const dataInterceptor = function (axios: AxiosInstance) {
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const { status, data, config } = response

      if (status >= 200 || status < 300) {
        if ((config as any)?.use) {
          message.success((config as any)?.message || '操作成功')
        }
        return data.data
      }
      return response
    },
    (error) => {
      const { data } = error.response || {}
      if (data) {
        const { code } = data
        if (code === 'token_exception') {
          window.localStorage.clear()
          // eslint-disable-next-line no-restricted-globals
          location.href = RouterMap.login
        } else {
          message.error(data.message)
        }
      }
      return Promise.reject(error)
    },
  )
  axios.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('token')
      config.headers['Authorization'] = 'Bearer ' + token
      return {
        ...config,
      }
    },
    (res) => {
      if (res && res.response) {
        message.error(res.response.data.message || '发生异常')
      }
      return Promise.reject(res)
    },
  )
}
