import jspreadsheet, { Options, JSpreadsheetElement, EventsOptions } from 'jspreadsheet-ce'
import { debounce, isArray, isEqual, isNumber, merge } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useWindowSize } from 'react-use'
import { useSocketIO } from './socket'
import { Response } from '@/api/interceptors/data'
import { message } from 'antd'
import { ActionType } from '../interface'
import { useResize } from './resize'
import { fromEvent } from 'rxjs'
import { bufferTime, filter, distinct } from 'rxjs/operators'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import 'jsuites/dist/jsuites.css'

interface Broadcast {
  x?: number
  y?: number
  x1?: number
  y1?: number
  value?: string | string[]
  key?: string
  type: ActionType
  page: number
  who: string
  id: string
}

const ActionCache = new Map<string, Set<Broadcast>>()
let SocketChange = false

function resizeSheet(columns: HomePage.SheetProps['columns'], cell: number, width: number) {
  const remaining = width - (cell - columns.length + 1) * 50
  const columnsOptions = columns.map((column) => {
    column.width = remaining / columns.length
    return column
  })
  return columnsOptions
}

function getExCell(x: number, y: number) {
  const code = 65
  return `${String.fromCharCode(code + x)}${y + 1}`
}

function getCellDom(x: string, sheet: JSpreadsheetElement): HTMLElement {
  return sheet.getCell(x)
}

function checkIDFormCache(cache: typeof ActionCache, data: any) {
  let exist = false
  for (const [key, values] of cache.entries()) {
    // eslint-disable-next-line no-loop-func
    values.forEach((value) => {
      const e = {
        x: value.x,
        y: value.y,
      }
      if (isEqual(e, data)) {
        exist = true
      }
    })
  }
  return exist
}

function g(n: number, m: number) {
  const min = Math.min(n, m)
  const max = Math.max(n, m)
  let a = []
  for (let i = min; i <= max; i++) {
    a.push(i)
  }
  return a
}

function focus(data: Broadcast, sheet: JSpreadsheetElement) {
  const { x, y, x1, y1, who } = data
  if (isNumber(x) && isNumber(y) && isNumber(x1) && isNumber(y1)) {
    const xa = g(x, x1)
    const ya = g(y, y1)
    for (let i = 0; i < xa.length; i++) {
      for (let j = 0; j < ya.length; j++) {
        const cell = getCellDom(getExCell(xa[i], ya[j]), sheet)
        cell.classList.add('td-active', 'readonly')
        cell.setAttribute('data-user', who)
      }
    }
  }
}

function blur(data: Broadcast, sheet: JSpreadsheetElement) {
  const { x, y, x1, y1 } = data
  if (isNumber(x) && isNumber(y) && isNumber(x1) && isNumber(y1)) {
    const xa = g(x, x1)
    const ya = g(y, y1)
    for (let i = 0; i < xa.length; i++) {
      for (let j = 0; j < ya.length; j++) {
        const cell = getCellDom(getExCell(xa[i], ya[j]), sheet)
        if (i === 0 && x === 0) {
          cell.classList.remove('td-active')
        } else {
          cell.classList.remove('td-active', 'readonly')
        }
        cell.removeAttribute('data-user')
      }
    }
  }
}

export const useSheet = (
  socket: ReturnType<typeof useSocketIO>,
  {
    columns,
    source,
    sheetRef,
    user,
    dataSourceFlag,
    ...optionsEvents
  }: Omit<HomePage.SheetProps, 'socket'> & { sheetRef: any; user: any } & EventsOptions,
) => {
  const sheet = useRef<JSpreadsheetElement>()

  const defaultOptions = useMemo<
    Options & {
      minDimensions: [number, number]
    }
  >(() => {
    const [cell, row] = [12, 25]
    return {
      minDimensions: [cell, row],
    }
  }, [])
  const { width, height } = useWindowSize()
  const size = useResize(sheetRef.current?.parentElement)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounce(() => {
      if (sheet.current) {
        const cell = defaultOptions.minDimensions[0]
        const columnsOptions = resizeSheet(columns, cell, size.width)
        columnsOptions.forEach((column, index) => {
          sheet.current && sheet.current.setWidth(index, column.width, 0)
        })
      }
    }, 1000),
    [width, height, size, sheet, defaultOptions, columns],
  )

  const onchange = useCallback(
    (...args: any) => {
      if (sheet.current) {
        // eslint-disable-next-line
        const [_h, _v, x, y, value, _old] = args
        const page = sheet.current.pageNumber
        const row = columns[+x]
        if (!SocketChange) {
          socket.emit('broadcast', {
            type: ActionType.CHANGE,
            page,
            x: +x,
            y: +y,
            value,
            who: user.username,
            id: user.id,
          })
          const flagId = dataSourceFlag[y].flag
          if (flagId) {
            socket.emit('updateLanguageValue', {
              categoryId: row.id,
              flagId: dataSourceFlag[y].flag,
              value,
            })
          }
        } else {
          SocketChange = false
        }
      }
    },
    [columns, socket, user.username, user.id, dataSourceFlag],
  )

  const onselection = useCallback(
    (...args: any) => {
      const [_e, x, y, x1, y1] = args
      if (sheet.current) {
        const page = sheet.current.pageNumber
        if (!checkIDFormCache(ActionCache, { x, y })) {
          socket.emit('broadcast', {
            type: ActionType.ACTIVE,
            page,
            x,
            y,
            x1,
            y1,
            who: user.username,
            id: user.id,
          })
        }
      }
    },
    [socket, user],
  )

  const onblur = useCallback(
    (...args: any) => {
      if (sheet.current) {
        const [_e, x, y] = args

        const page = sheet.current.pageNumber
        socket.emit('broadcast', {
          type: ActionType.BLUR,
          page,
          who: user.username,
          id: user.id,
        })
      }
    },
    [socket, user],
  )

  const onchangepage = useCallback(
    (...args) => {
      const [_, currentPage, oldPage] = args
      for (const [key, values] of ActionCache.entries()) {
        // eslint-disable-next-line no-loop-func
        values.forEach((value) => {
          if (sheet.current) {
            const { page, x, y, type, id } = value
            if (isNumber(x) && isNumber(y) && currentPage === page) {
              if (type === ActionType.ACTIVE) {
                focus(value, sheet.current)
              }
              if (type === ActionType.CHANGE) {
                SocketChange = true
                sheet.current.setValue(getExCell(x, y), value.value, false)
                // 清除所有消费过的信息
                const data = ActionCache.get(id)
                if (data) {
                  data.delete(value)
                  ActionCache.set(id, data)
                }
              }
            }
          }
        })
      }
    },
    [sheet],
  )

  const initSheet = useCallback(() => {
    if (sheet.current) {
      sheet.current.destroy()
    }
    const cell = defaultOptions.minDimensions?.[0] as number
    const columnsOptions = resizeSheet(columns, cell, size.width)
    const options: Options = {
      ...defaultOptions,
      ...optionsEvents,
      data: source,
      onchange,
      onselection,
      onblur,
      onchangepage,
      columns: columnsOptions,
      search: true,
      pagination: 25,
      text: {
        showingPage: '当前第 {0} 页，总共有 {1} 页',
        insertANewColumnBefore: '👈 向左插入一列',
        insertANewColumnAfter: '👉 向右插入一列',
        insertANewRowBefore: '👆 向上插入一行',
        insertANewRowAfter: '👇 向下插入一行',
        deleteSelectedRows: '🐱 删除选中行',
        deleteSelectedColumns: '🐱 删除选中列',
        renameThisColumn: '👎 重命名当前列',
        copy: '🤔 拷贝',
        paste: '😄 粘贴',
        saveAs: '😩 另存为',
        orderAscending: '🔥 往上排',
        orderDescending: '🔥 往下排',
        about: '🥱 关于',
      },
      updateTable: (el, cell, x, y, source, value, id) => {
        if (x >= columns.length) {
          cell.classList.add('readonly')
        }
        if (y >= dataSourceFlag.length) {
          cell.classList.add('readonly')
        }
      },
    }
    if (sheetRef) {
      sheet.current = jspreadsheet(sheetRef.current, options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, dataSourceFlag, source, defaultOptions])

  useEffect(() => {
    if (columns.length > 0) {
      // init
      initSheet()
    }
  }, [columns, initSheet])

  const broadcast = (data: Broadcast) => {
    if (sheet.current) {
      console.log('broadcast', data)

      const { x, y, type, id, value, page, key } = data

      // clear current user focus
      if (ActionCache.has(id)) {
        const values = ActionCache.get(id)
        values?.forEach((value) => {
          sheet.current && blur(value, sheet.current)
        })
      }

      if (isNumber(x) && isNumber(y)) {
        const values = ActionCache.get(id)
        if (type === ActionType.ACTIVE) {
          if (sheet.current.pageNumber === page) {
            focus(data, sheet.current)
          }
          const value = new Set([data])
          ActionCache.set(id, value)
        }
        if (type === ActionType.CHANGE) {
          if (sheet.current.pageNumber === page) {
            SocketChange = true
            sheet.current.setValue(getExCell(x, y), value, false)
          } else {
            // 未消费时放入缓存中
            if (values) {
              values.add(data)
              ActionCache.set(id, values)
            }
          }
        }
        if (type === ActionType.ADD_LINE && key && isArray(value)) {
          SocketChange = true
          sheet.current.insertRow([key].concat(value), 0, true)
          const cell = getCellDom('A1', sheet.current)
          cell.classList.add('readonly')
        }
        if (type === ActionType.DEL_LINE) {
          sheet.current.deleteRow(y, 1)
        }
      }

      if (type === ActionType.BLUR) {
        if (ActionCache.has(id)) {
          const values = ActionCache.get(id)
          values?.forEach((value) => {
            sheet.current && blur(value, sheet.current)
          })
          ActionCache.delete(id)
        }
      }
    }
  }

  const broadcastBuffer = (x: Broadcast[]) => {
    x.map((o) => broadcast(o))
  }

  const websocket_exception_event = (data: Response<unknown>) => {
    message.error(data.message)
  }

  useEffect(() => {
    fromEvent(socket, 'broadcast')
      .pipe(
        bufferTime<Broadcast>(2000),
        filter((x) => x.some((o) => o.id !== user.id)),
        distinct(),
      )
      .subscribe(broadcastBuffer)
    socket.on('websocket_exception_event', websocket_exception_event)
    socket.on('disconnect', function () {
      console.log('Disconnected')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return sheet.current
}
