import { chrysalis } from './store'
import { createBrowserHistory, createHashHistory } from 'history'
import { getModules } from './store/modules'
import { createLogger } from 'redux-logger'
import { isClient } from './utils/config'

const history = isClient() ? createHashHistory() : createBrowserHistory()

const logger = createLogger({
  collapsed: true,
})
const middleWares = process.env.NODE_ENV === 'production' ? [] : [logger]

chrysalis.module(getModules())
;(chrysalis.start as any)(
  document.getElementById('root') as HTMLDivElement,
  history,
  middleWares,
  undefined,
)

if (module.hot) {
  module.hot.accept('./store/modules', () => {
    // eslint-disable-next-line
    const { getModules: getNewModules } = require('./store/modules')
    chrysalis.hotReload(getNewModules())
  })
}
