import React from 'react'
import Router from '../router'
import { hot } from 'react-hot-loader/root'
import { IRootProviderProps, RootProvider } from 'chrysalis'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import 'antd/dist/antd.min.css'
import './App.css'

const RootApp: React.FunctionComponent<IRootProviderProps> = (props) => {
  return (
    <RootProvider {...props}>
      <ConfigProvider locale={zhCN}>
        <Router></Router>
      </ConfigProvider>
    </RootProvider>
  )
}

export const HotRootApp = hot(RootApp)
