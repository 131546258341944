import { Route, Switch, Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { ControlOutlined, FormOutlined } from '@ant-design/icons'
import { ErrorBoundary } from '../components/ErrorBoundary/index'
import { RouterMap, RouterEnum } from './const'
import { FC, Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { groupsSelector, loadingSelector, routeSelector } from '@/view/login/selectors'
import { gRouterAction } from '@/view/login/actions'

/** layouts */
import BasicLayout from '../layouts/BasicLayout'

/** pages */
import { LoginView } from '../view/login'
import SetPage from '../view/set'
import EmptyView from '@/view/lite/empty'
import ErrorView from '@/view/lite/error'
import LoadingView from '@/view/lite/loading'

const NormalPage = RouterMap.main
export const routerConfig = [
  {
    path: RouterMap.login,
    key: RouterEnum.LOGIN,
    component: LoginView,
  },
  {
    path: RouterMap.main,
    key: RouterEnum.MAIN,
    component: BasicLayout,
    redirect: RouterMap.editor,
    children: [
      {
        title: '语言包编辑',
        path: RouterMap.editor,
        key: RouterEnum.EDITOR,
        component: BasicLayout,
        icon: <FormOutlined />,
      },
      {
        title: '设置',
        path: RouterMap.language,
        key: RouterEnum.LANGUAGE,
        component: SetPage,
        icon: <ControlOutlined />,
      },
    ],
  },
]

export type RouterConfig = typeof routerConfig

const renderRoute = (router: RouterConfig, layout: FC<any> | void) => {
  const LayoutFC = layout || Fragment
  return (
    <ErrorBoundary>
      {router.map((item) => {
        if (item.children) {
          return (
            <Switch key={item.key}>
              <Route
                exact
                path={item.path}
                render={() => <Redirect to={(item as any).redirect} />}
              />
              {renderRoute(item.children as unknown as RouterConfig, item.component)}
            </Switch>
          )
        } else {
          return (
            <Route key={item.key} path={item.path}>
              <LayoutFC>
                <item.component />
              </LayoutFC>
            </Route>
          )
        }
      })}
    </ErrorBoundary>
  )
}

const RegisterRouting = () => {
  const page = window.localStorage.getItem('token') ? NormalPage : RouterMap.login
  // eslint-disable-next-line no-restricted-globals
  const { pathname } = location

  const loading = useSelector(loadingSelector)
  const groups = useSelector(groupsSelector)
  const router = useSelector(routeSelector)
  const dispatch = useDispatch()

  const routers = useMemo(() => {
    return router
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, router])

  useEffect(() => {
    if (!pathname.includes('/login')) {
      dispatch(gRouterAction.request())
    }
  }, [dispatch, pathname])

  if (loading && !pathname.includes('/login')) return <LoadingView />

  if (!groups && !pathname.includes('/login')) {
    return <ErrorView />
  }

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={page} />} />
      {renderRoute(routers)}
      <Route path="*" component={EmptyView} />
    </Switch>
  )
}

const AuthRouter: FC<any> = ({ children }) => {
  const token = window.localStorage.getItem('token')
  const history = useHistory()

  useEffect(() => {
    if (!token) {
      history.push(RouterMap.login)
    }
  }, [history, token])

  return children
}

const RootRouter = () => {
  return (
    <AuthRouter>
      <RegisterRouting />
    </AuthRouter>
  )
}

export const getRoutesConfig = (key: RouterEnum, routerConfig: RouterConfig) => {
  const source = (routes: any) => {
    return routes.map((ele: any) => {
      return {
        title: ele.title,
        name: ele.key,
        path: ele.path,
        icon: ele.icon ? ele.icon : '',
        children: ele.children ? source(ele.children) : null,
      }
    })
  }
  const find = (routes: any) => {
    let target = []
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].key === key) {
        target = routes[i].children
        break
      } else {
        if (routes[i].children && routes[i].children.length > 0) {
          find(routes[i].children)
        }
      }
    }
    return target
  }
  return source(find(routerConfig))
}
export default RootRouter
