import { Chrysalis } from 'chrysalis'
import { Effect, all } from 'redux-saga/effects'
import { History } from 'history'
import { HotRootApp } from '../web/App'
import { Middleware, Reducer } from 'redux'
import { devCreateStore } from 'chrysalis/dist-src/store/createStore.dev'
import { prodCreateStore } from 'chrysalis/dist-src/store/createStore.prod'
import { routerMiddleware } from 'connected-react-router'
import ReactDOM from 'react-dom'
import sagaMiddlewareFactory from 'redux-saga'

const delay = (ms: number) =>
  new Promise((res) => {
    window.setTimeout(() => {
      res(null)
    }, ms)
  })

function configureStore(
  history: History,
  rootReducer: Reducer,
  sagas: Effect[],
  initState: object = {},
  preMiddleware: Middleware[] = [],
  middleware: Middleware[] = [],
) {
  const sagaMiddleware = sagaMiddlewareFactory()
  const store = (process.env.NODE_ENV === 'production'
    ? prodCreateStore
    : devCreateStore)(rootReducer, initState || {}, [
    routerMiddleware(history),
    ...preMiddleware,
    sagaMiddleware,
    ...middleware,
  ])
  let sagaTask = sagaMiddleware.run(function* () {
    try {
      yield all(sagas)
    } catch (e) {
      console.error(e)
    }
  })
  return {
    ...store,
    replaceReducer: (reducer: Reducer) => {
      store.replaceReducer(reducer)
    },
    replaceSaga: (saga: Effect[]) => {
      if (sagaTask) {
        sagaTask.toPromise().then(() => {
          sagaTask = sagaMiddleware.run(function* () {
            try {
              yield all(saga)
            } catch (e) {
              console.error(e)
            }
          })
        })
        sagaTask.cancel()
      }
    },
  }
}

const start = async function (
  that: any,
  root: HTMLDivElement,
  history: History,
  customMiddleware: Middleware[],
  initialState: any,
  preMiddleware?: Middleware[],
) {
  //
  await delay(10) // wait for mitsuha initialize
  // var that = this as any;
  const store = configureStore(
    history,
    that.composeReducer(that.modules, history),
    that.flattenSaga(that.modules),
    { ...initialState },
    preMiddleware,
    customMiddleware,
  )
  that.store = store
  that.root = root
  that.history = history
  const HotRootApp = that.HotRootApp
  ReactDOM.render(<HotRootApp store={store} history={history} />, root)
  return that
}

const _chrysalis = new Chrysalis(HotRootApp)

_chrysalis.start = async function (...args) {
  await start(this, ...args)
  return this
}

export const chrysalis = _chrysalis
