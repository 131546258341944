import { Button } from 'antd'
import { useCallback } from 'react'

export default () => {
  const handleReload = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }, [])

  return (
    <div
      style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      加载失败{' '}
      <Button style={{ marginLeft: 10 }} onClick={handleReload}>
        点击刷新
      </Button>
    </div>
  )
}
