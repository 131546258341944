import { AnyAction } from 'redux'
import { IExampleState } from './interfaces'
import { getExample, updateExampleAction, add } from './actions'
import update from 'immutability-helper'

const defaultState: IExampleState = {
  loading: false,
  count: 0,
}

export default (state: IExampleState = defaultState, action: AnyAction): IExampleState => {
  if (updateExampleAction.match(action)) {
    return {
      ...state,
      ...action.payload,
    }
  }

  if (getExample.success.match(action)) {
    return {
      ...state,
      example: action.payload,
    }
  }

  if (add.match(action)) {
    return update(state, {
      count: {
        $set: action.payload,
      },
    })
  }

  return state
}
