import { AnyAction } from 'redux'
import { LoginState } from './interfaces'
import { loginAction, loadingAction, gRouteAction, gRouterAction } from './actions'
import { routerConfig } from '@/router'
import update from 'immutability-helper'

const defaultState: LoginState = {
  username: '',
  password: '',
  token: '',
  loading: false,
  user: JSON.parse(localStorage.getItem('user') || '{}'),
  router: routerConfig,
  groupsSuccess: false,
}

export default (state: LoginState = defaultState, action: AnyAction): LoginState => {
  if (loginAction.success.match(action)) {
    return update(state, {
      token: {
        $set: action.payload.token,
      },
      user: {
        $set: action.payload.user,
      },
    })
  }

  if (loadingAction.match(action)) {
    return update(state, {
      loading: {
        $set: action.payload,
      },
    })
  }

  if (gRouteAction.match(action)) {
    return update(state, {
      router: {
        $set: action.payload,
      },
    })
  }

  if (gRouterAction.success.match(action)) {
    return update(state, {
      groupsSuccess: {
        $set: true,
      },
    })
  }

  if (gRouterAction.failure.match(action)) {
    return update(state, {
      groupsSuccess: {
        $set: false,
      },
    })
  }

  return state
}
