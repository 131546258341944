import { useState } from 'react'
import { debounce } from 'lodash'

export function useResize(el?: HTMLElement | null) {
  let w = 0,
    h = 0
  if (el) {
    const { width, height } = el.getBoundingClientRect()
    w = width
    h = height
  }
  const [size, updateSize] = useState({
    width: w,
    height: h,
  })
  const update = debounce((width, height) => {
    updateSize({
      width,
      height,
    })
  }, 10)
  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const { width, height } = entry.contentRect
      if (!(width === size.width && height === size.height)) {
        update(width, height)
      }
    }
  })

  if (!el)
    return {
      width: document.body.clientWidth,
      height: document.body.clientHeight,
    }

  resizeObserver.observe(el)

  return size
}
