export const RouterMap = {
  main: '/main',
  editor: '/main/editor',
  language: '/main/language',
  login: '/login',
  empty: '*',
}

export enum RouterEnum {
  MAIN = 'MAIN',
  EDITOR = 'EDITOR',
  LANGUAGE = 'LANGUAGE',
  LOGIN = 'LOGIN',
  EMPTY = 'EMPTY',
}
