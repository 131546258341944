import * as monaco from 'monaco-editor'
import { useCallback, useEffect, useRef, useState } from 'react'

let editor: monaco.editor.IStandaloneCodeEditor | null = null
export default function ({
  onChange,
  clearValue,
}: {
  onChange: (v: string) => void
  clearValue: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      editor = monaco.editor.create(ref.current, {
        language: 'json',
        theme: 'vs-dark',
      })
      editor.getModel()?.updateOptions({ tabSize: 2 })
      // editor.onDidChangeModelContent(handleUpdate)
      editor.onDidBlurEditorText(handleUpdate)
    }
    return () => {
      editor = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clearValue && editor) {
      editor.setValue('')
    }
  }, [clearValue])

  const handleUpdate = () => {
    if (editor) {
      const value = editor.getValue()
      if (value) {
        onChange(value)
      }
    }
  }

  return <div style={{ height: 340 }} ref={ref}></div>
}
