import service from './index'

export const getGroups = () => {
  return service.get<SetPage.GroupType[]>('/groups')
}

export const upload = (data: any, categoryId: string) => {
  return service.post(
    '/languages/category',
    {
      data,
      categoryId,
    },
    {
      use: true,
      message: '上传成功',
    },
  )
}

export const languages = (categoryIds: string[]) => {
  return service.get<
    { id: string; value: string; categoryId: string; flag: { key: string; id: string } }[]
  >('/v2/languages', {
    categoryIds: categoryIds,
  })
}

export const createCategories = (data: any) => {
  return service.post('/categories', {
    ...data,
  })
}

export const editCategories = (id: string, data: any) => {
  return service.put(`/categories/${id}`, {
    ...data,
  })
}

export const categories = () => {
  return service.get('/categories')
}

export const addLanguageLine = (data: any) => {
  return service.post<any>('/languages/line', data, {
    use: true,
    message: '添加成功',
  })
}

export const editLanguageLine = (data: any) => {
  return service.put<any>('/languages/line', data, {
    use: true,
    message: '修改成功',
  })
}

export const deleteRow = (id: string) => {
  return service.delete<any>(
    '/flags/' + id,
    {},
    {
      use: true,
      message: '删除成功',
    },
  )
}

export const languagePublish = (data: { groupId: string }, config = {}) => {
  return service.post<any>('/languages/publish', data, {
    use: true,
    message: '发布成功',
  })
}

export const getPackagesByGroupId = (id: string) => {
  return service.get<SetPage.PackageItem[]>(`/categories?groupId=${id}`)
}

export const deletePackageById = (id: string) => {
  return service.delete(
    `/categories/${id}`,
    {},
    {
      use: true,
      message: '删除成功',
    },
  )
}

export const deleteLanguageDataByGroupID = (id: string) => {
  return service.delete(
    `/languages/${id}/clear`,
    {},
    {
      use: true,
      message: '删除成功',
    },
  )
}

export const patchPackageByIdWithDisabled = (id: string, status: boolean) => {
  return service.patch(
    `/categories/${id}/disabled/${status}`,
    {},
    {
      use: true,
    },
  )
}

export const translate = (groupId: string, value: string) => {
  return service.get<{
    [key: string]: string
  }>(`/languages/${groupId}/translate/${value}`)
}

export const languageMultiple = (
  groupId: string,
  value: {
    key: string
    value: string
  }[],
) => {
  return service.post<{
    [key: string]: string
  }>(
    `/languages/json`,
    {
      data: value,
      groupId: groupId,
    },
    {
      use: true,
      message: '添加成功',
    },
  )
}
