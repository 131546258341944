import { call, put, select, takeLatest } from 'redux-saga/effects'
import { gRouteAction, gRouterAction, loadingAction, loginAction } from './actions'
import { push } from 'react-router-redux'
import { RouterMap } from '../../router/const'
import LoginService from '../../api/login'
import { getGroups } from '@/api/package'
import { routeSelector } from './selectors'
import { RouterConfig } from '@/router'
import EditorView from '@/view/editer/index'

const delay = (ms: number) =>
  new Promise((res) => {
    window.setTimeout(() => {
      res(null)
    }, ms)
  })

function* loginWorker({ payload }: ReturnType<typeof loginAction.request>) {
  try {
    yield put(loadingAction(true))
    const data: PromiseType<ReturnType<typeof LoginService.login>> = yield call(
      LoginService.login,
      payload,
    )

    yield put(
      loginAction.success({
        token: data.token,
        user: data.user,
      }),
    )

    window.localStorage.setItem('token', data.token)
    window.localStorage.setItem('user', JSON.stringify(data.user))

    const config: ReturnType<typeof gRouter> = yield call(gRouter)

    yield put(loadingAction(false))
    yield put(push((config as any)[1].children[0].redirect))
  } catch (e) {
    yield put(loginAction.failure())
    yield put(loadingAction(false))
  }
}

function* gRouter() {
  yield put(loadingAction(true))

  try {
    const groups: PromiseType<ReturnType<typeof getGroups>> = yield call(getGroups)
    const routerConfig: RouterConfig = yield select(routeSelector)
    const config = routerConfig as any
    config[1].children[0].redirect = RouterMap.editor + '/' + groups[0].id
    config[1].children[0].children = []
    groups.forEach((c) => {
      config[1]?.children?.[0]?.children.push({
        path: RouterMap.editor + '/' + c.id,
        key: c.key,
        title: c.name,
        component: EditorView,
      })
    })
    yield put(gRouteAction(config))
    // yield delay(200)
    yield put(loadingAction(false))
    yield put(gRouterAction.success())
    return config
  } catch (e) {
    yield put(loadingAction(false))
    yield put(gRouterAction.failure())
  }
}

export default function* saga() {
  yield takeLatest(loginAction.request.match, loginWorker)
  yield takeLatest(gRouterAction.request.match, gRouter)
}
