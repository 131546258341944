import service from './index'
import { LoginState } from '../view/login/interfaces'

interface User {
  token: string
  user: Record<string, string>
}

class ServiceLogin {
  login(params: Pick<LoginState, 'password' | 'username'>) {
    return service.post<User>('/login', params)
  }

  logout() {
    return
  }
}

export default new ServiceLogin()
