import React, { ErrorInfo, ReactNode } from 'react'
import './style.less'

interface IProps {
  children: ReactNode
  render?: () => ReactNode
}

interface IState {
  error?: Error
  errorInfo?: ErrorInfo
}

const defaultRenderer = () => null

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      const { render: renderer = defaultRenderer } = this.props
      return renderer()
    }
    return this.props.children
  }
}
